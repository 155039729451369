/*!
 * Krajee Font Awesome Theme styling for bootstrap-star-rating.
 * This file must be loaded after 'star-rating.css'.
 *
 * @see http://github.com/kartik-v/bootstrap-star-rating
 * @author Kartik Visweswaran <kartikv2@gmail.com>
 */
.theme-krajee-fa .star {
    font-size: 0.8em;
}

.theme-krajee-fa .caption {
    margin-top: -0.2em;
}